import React, { useEffect, useState } from "react";
import { Client } from "../../types/client.type";
import {
  Input,
  Row,
  Col,
  Divider,
  Typography,
  Image,
  Menu,
  Dropdown,
  Button,
  Table,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Address } from "../../types/address.type";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { API } from "../../config/config";

const { Search } = Input;
const { Title, Text } = Typography;

export default function Clients() {
  const navigate = useNavigate();
  const [clients, setClients] = useState<Client[]>([]);
  const [query, setQuery] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalClients, setTotalClients] = useState<number>(0);

  const fetchClients = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/admin/clients`, {
        params: {
          query,
          city: searchCity,
          page: currentPage,
          limit: pageSize,
        },
      });
      setClients(response.data.clients);
      setTotalClients(response.data.totalClients);
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [query, searchCity, currentPage, pageSize]);

  const handleSearch = (value: string) => {
    setQuery(value);
    setCurrentPage(1);
  };

  const handleCitySearch = (value: string) => {
    setSearchCity(value);
    setCurrentPage(1);
  };

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Фамилия",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "surname",
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Пол",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Дата Рождения",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "Фото",
      dataIndex: "photoUri",
      key: "photoUri",
      render: (photoUri: string) => (
        <Image
          width={50}
          src={`${API}/${photoUri}`}
          preview={{ src: `${API}/${photoUri}` }}
          alt="Фото"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: "Адреса",
      dataIndex: "address",
      key: "address",
      render: (address: Address[]) =>
        address.map((addr, index) => (
          <div key={addr._id}>
            <Text>
              {`${index + 1}. ${addr.city || "Не указано"}, ${
                addr.street || "Не указано"
              }, 
                дом ${addr.house || "Не указано"}, вход ${
                addr.entrance || "Не указано"
              } 
                кв ${addr.apartment || "Не указано"}, ${
                addr.floor ? `${addr.floor} этаж` : "Этаж не указан"
              }`}
            </Text>
          </div>
        )),
    },

    {
      title: "",
      key: "actions",
      render: (_: any, client: Client) => {
        const menu = (
          <Menu>
            <Menu.Item
              onClick={() =>
                navigate(`/${client._id}/client/${client.name}/orders`)
              }
            >
              Заказы
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(`/reviews/client/${client._id}/${client.name}`)
              }
            >
              Отзывы
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <Button type="link">
              Действия <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  return (
    <div style={{ padding: "24px" }}>
      <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
        Управление Клиентами
      </Title>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={12}>
          <Search
            placeholder="Поиск по имени или телефону"
            onSearch={handleSearch}
            allowClear
            enterButton
          />
        </Col>
        <Col span={12}>
          <Search
            placeholder="Поиск по городу"
            onSearch={handleCitySearch}
            allowClear
            enterButton
          />
        </Col>
      </Row>
      <Divider />

      <Table
        columns={columns}
        dataSource={clients}
        rowKey={(record) => record._id}
        loading={loading}
        pagination={{
          current: currentPage,
          total: totalClients,
          pageSize: pageSize,
          onChange: (page, size) => {
            setCurrentPage(page);
            if (size !== pageSize) setPageSize(size);
          },
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}
