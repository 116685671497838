import React, { CSSProperties } from "react";
import { useAuth } from "../../contexts/auth.context";
import { Layout, Avatar, Typography, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";

const { Header: AntHeader } = Layout;
const { Title, Text } = Typography;

export default function Header({ darkTheme }: { darkTheme: boolean }) {
  const { authState } = useAuth();
  const userName = authState?.username || "Guest";

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    backgroundColor: darkTheme ? "#001529" : "#fff",
    color: darkTheme ? "#fff" : "#000",
    borderRadius: 8,
  };

  const titleStyle = {
    margin: 0,
    color: darkTheme ? "#fff" : "#000",
  };

  const textStyle = {
    color: darkTheme ? "#fff" : "#000",
  };

  return (
    <AntHeader style={headerStyle}>
      <Title level={3} style={titleStyle}>
        Yoja Admin
      </Title>
      <Space>
        <Avatar icon={<UserOutlined />} />
        <Text style={textStyle}>{userName}</Text>
      </Space>
    </AntHeader>
  );
}
