import {
  FiBriefcase,
  FiHome,
  FiUsers,
  FiTruck,
  FiDollarSign,
  FiFileText,
  FiFilePlus,
  FiTag,
} from "react-icons/fi";

export const items = [
  { key: "/", icon: <FiHome />, label: "Главная" },
  { key: "/sellers", icon: <FiBriefcase />, label: "Селлеры" },
  { key: "/clients", icon: <FiUsers />, label: "Клиенты" },
  { key: "/couriers", icon: <FiTruck />, label: "Курьеры" },
  { key: "/pricings", icon: <FiDollarSign />, label: "Цены" },
  { key: "/subcategories", icon: <FiTag />, label: "Подкатегории" },
  {
    key: "/requests",
    icon: <FiFileText />,
    label: "Заявки",
    children: [
      {
        key: "/requests/withdrawals",
        icon: <FiDollarSign />,
        label: "Заявки вывода",
      },
      {
        key: "/requests/verification",
        icon: <FiFilePlus />,
        label: "Заявки активации",
      },
    ],
  },
];
