import React, { useEffect, useState } from "react";
import useGetRequest from "../../hooks/useGetRequest";
import { Stats } from "../../types/stats.type";
import StatCard from "../../components/StatCard/statCard.component";
import { Row, Col, Spin, Card, Typography } from "antd";
import { Line } from "react-chartjs-2";
import {
  UserOutlined,
  ShoppingCartOutlined,
  CarOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const { data, loading } = useGetRequest(`/admin/stats`);
  const [stats, setStats] = useState<Stats>();

  useEffect(() => {
    if (data) {
      setStats(data);
    }
  }, [data]);

  if (loading) {
    return <Spin size="large" />;
  }

  const usersChart = {
    labels: stats?.sellersByMonth.map((item) => item.name),
    datasets: [
      {
        label: "Селлеры",
        data: stats?.sellersByMonth.map((item) => item.count),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "Клиенты",
        data: stats?.clientsByMonth.map((item) => item.count),
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        fill: true,
      },
      {
        label: "Курьеры",
        data: stats?.couriersByMonth.map((item) => item.count),
        borderColor: "rgba(252, 186, 3, 1)",
        backgroundColor: "rgba(252, 186, 3, 0.2)",
        fill: true,
      },
    ],
  };

  const ordersChart = {
    labels: stats?.ordersByMonth.map((item) => item.name),
    datasets: [
      {
        label: "Количество",
        data: stats?.ordersByMonth.map((item) => item.count),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
    ],
  };

  const amountChart = {
    labels: stats?.ordersByMonth.map((item) => item.name),
    datasets: [
      {
        label: "Сумма",
        data: stats?.ordersByMonth.map((item) => item.totalAmount),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography.Title
        level={3}
        style={{ textAlign: "center", marginBottom: "24px" }}
      >
        Статистика
      </Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <StatCard
            title="Продавцы"
            value={stats?.totalSellers || 0}
            suffix=""
            precision={0}
            icon={
              <UserOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
            }
          />
        </Col>
        <Col span={6}>
          <StatCard
            title="Клиенты"
            value={stats?.totalClients || 0}
            suffix=""
            precision={0}
            icon={
              <ShoppingCartOutlined
                style={{ fontSize: "24px", color: "#52c41a" }}
              />
            }
          />
        </Col>
        <Col span={6}>
          <StatCard
            title="Курьеры"
            value={stats?.totalCouriers || 0}
            suffix=""
            precision={0}
            icon={
              <CarOutlined style={{ fontSize: "24px", color: "#faad14" }} />
            }
          />
        </Col>
        <Col span={6}>
          <StatCard
            title="Заказы (месяц)"
            value={stats?.orderCountThisMonth || 0}
            suffix=""
            precision={0}
            icon={
              <ShoppingCartOutlined
                style={{ fontSize: "24px", color: "#ff4d4f" }}
              />
            }
          />
        </Col>
        <Col span={6}>
          <StatCard
            title="Общий объём продаж"
            value={stats?.totalSales || 0}
            suffix="₽"
            precision={2}
            icon={
              <DollarCircleOutlined
                style={{ fontSize: "24px", color: "#52c41a" }}
              />
            }
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col span={12}>
          <Card title="Пользователи по месяцам">
            <Line data={usersChart} options={chartOptions} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Количество заказов по месяцам">
            <Line data={ordersChart} options={chartOptions} />
          </Card>
        </Col>
        <Col span={24} style={{ marginTop: "20px" }}>
          <Card title="Продажи по месяцам (₽)">
            <Line data={amountChart} options={chartOptions} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
