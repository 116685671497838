import React, { useEffect, useState } from "react";
import { Courier, CourierStatus, CourierType } from "../../types/couriers.type";
import {
  Input,
  Row,
  Col,
  Select,
  Typography,
  Divider,
  Table,
  Dropdown,
  Button,
  Menu,
  Image,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { API } from "../../config/config";

const { Search } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

export default function Couriers() {
  const navigate = useNavigate();
  const [couriers, setCouriers] = useState<Courier[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<CourierStatus | "">("");
  const [typeFilter, setTypeFilter] = useState<CourierType | "">("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);

  const fetchCouriers = async (page = 1) => {
    setLoading(true);
    try {
      const response = await api.get(`/admin/couriers`, {
        params: {
          query,
          city: searchCity,
          status: statusFilter,
          type: typeFilter,
          page,
          limit: pageSize,
        },
      });
      setCouriers(response.data.couriers);
      setTotal(response.data.totalCouriers);
    } catch (error) {
      console.error("Error fetching couriers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCouriers(currentPage);
  }, [query, searchCity, statusFilter, typeFilter, currentPage, pageSize]);

  const handleSearch = (value: string) => {
    setQuery(value);
  };

  const handleCitySearch = (value: string) => {
    setSearchCity(value);
  };

  const handleStatusChange = (value: CourierStatus | "") => {
    setStatusFilter(value);
  };

  const handleTypeChange = (value: CourierType | "") => {
    setTypeFilter(value);
  };

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Фамилия",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "surname",
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Баланс",
      dataIndex: "balance",
      key: "balance",
      render: (balance: { amount: number }) =>
        new Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(balance.amount),
    },
    {
      title: "Фото",
      dataIndex: "photoUri",
      key: "photoUri",
      render: (photoUri: string) => (
        <Image
          width={50}
          src={`${API}/${photoUri}`}
          preview={{ src: `${API}/${photoUri}` }}
          alt="Фото"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: "В штате",
      dataIndex: "sellerName",
      key: "sellerName",
      render: (sellerName: string) => sellerName || "Не указано",
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) =>
        new Date(createdAt).toLocaleDateString("ru-RU"),
    },
    {
      title: "",
      key: "actions",
      render: (_: any, courier: Courier) => {
        const menu = (
          <Menu>
            <Menu.Item
              onClick={() =>
                navigate(`/${courier._id}/courier/${courier.name}/orders`)
              }
            >
              Заказы
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(`/${courier._id}/${courier.name}/transactions`)
              }
            >
              Транзакции
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(
                  `/requests/verification/${courier._id}/${courier.name}`
                )
              }
            >
              Заявки на активацию
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(`/requests/withdrawals/${courier._id}/${courier.name}`)
              }
            >
              Заявки на вывод
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <Button type="link">
              Действия <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div style={{ padding: "24px" }}>
      <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
        Управление Курьерами
      </Title>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={8}>
          <Search
            placeholder="Поиск по имени или телефону"
            onSearch={handleSearch}
            allowClear
            enterButton
          />
        </Col>
        <Col span={8}>
          <Search
            placeholder="Поиск по городу"
            onSearch={handleCitySearch}
            allowClear
            enterButton
          />
        </Col>
        <Col span={4}>
          <Select
            placeholder="Статусу"
            style={{ width: "100%" }}
            allowClear
            onChange={handleStatusChange}
          >
            <Option value={CourierStatus.ACTIVE}>Активен</Option>
            <Option value={CourierStatus.INACTIVE}>Неактивен</Option>
          </Select>
        </Col>
        <Col span={4}>
          <Select
            placeholder="Типу"
            style={{ width: "100%" }}
            allowClear
            onChange={handleTypeChange}
          >
            <Option value={CourierType.PEDESTRIAN}>Пешеход</Option>
            <Option value={CourierType.CYCLE_MOTO}>Велосипед/Мото</Option>
            <Option value={CourierType.AUTO}>Авто</Option>
            <Option value={CourierType.TRUCK}>Грузовик</Option>
          </Select>
        </Col>
      </Row>

      <Divider />

      <Table
        columns={columns}
        dataSource={couriers}
        rowKey={(record) => record._id}
        loading={loading}
        pagination={{
          current: currentPage,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setCurrentPage(page);
            if (size !== pageSize) setPageSize(size);
          },
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}
