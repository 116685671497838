import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Request, VerifyStatus } from "../../types/request.type";
import {
  DatePicker,
  Select,
  Space,
  Typography,
  Table,
  Image,
  Menu,
  Dropdown,
  Button,
  message,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import api from "../../api/api";
import { API } from "../../config/config";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function VerificationRequests() {
  const { courierId, name } = useParams();
  const [requests, setRequests] = useState<Request[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);
  const [status, setStatus] = useState<VerifyStatus | undefined>(undefined);

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/verify`, {
        params: {
          page,
          status,
          courierId,
          limit: pageSize,
          startDate: dateRange ? dateRange[0]?.format("DD.MM.YY") : undefined,
          endDate: dateRange ? dateRange[1]?.format("DD.MM.YY") : undefined,
        },
      });
      setRequests(response.data.requests);
      setTotal(response.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRequests();
  }, [page, pageSize, dateRange, courierId, status]);

  const handleAccept = async (id: string) => {
    try {
      await api.post(`/verify/${id}/accept`);
      message.success("Заявка успешно принята");
      fetchRequests();
    } catch (error: any) {
      message.error(
        error.response?.data?.error || "Ошибка при принятии заявки"
      );
    }
  };

  const handleReject = async (id: string) => {
    try {
      await api.post(`/verify/${id}/reject`);
      message.success("Заявка успешно отклонена");
      fetchRequests();
    } catch (error: any) {
      message.error(
        error.response?.data?.error || "Ошибка при отклонении заявки"
      );
    }
  };

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Фамилия",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: VerifyStatus) => {
        let statusColor;
        switch (status) {
          case VerifyStatus.Completed:
            statusColor = "green";
            break;
          case VerifyStatus.Rejected:
            statusColor = "red";
            break;
          case VerifyStatus.Pending:
            statusColor = "orange";
            break;
          default:
            statusColor = "gray";
        }
        return <span style={{ color: statusColor }}>{status}</span>;
      },
    },
    {
      title: "Фото с документом",
      dataIndex: "docUri",
      key: "docUri",
      render: (docUri: string) => (
        <Image
          width={50}
          src={`${API}/${docUri}`}
          preview={{ src: `${API}/${docUri}` }}
          alt="Фото"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      key: "actions",
      render: (_: any, request: Request) => {
        const { status, _id } = request;

        let menu = null;
        if (status === VerifyStatus.Pending) {
          menu = (
            <Menu>
              <Menu.Item onClick={() => handleAccept(_id)}>Принять</Menu.Item>
              <Menu.Item danger onClick={() => handleReject(_id)}>
                Отклонить
              </Menu.Item>
            </Menu>
          );
        } else if (status === VerifyStatus.Completed) {
          menu = (
            <Menu>
              <Menu.Item disabled>Завершено</Menu.Item>
            </Menu>
          );
        } else if (status === VerifyStatus.Rejected) {
          menu = (
            <Menu>
              <Menu.Item disabled>Отклонено</Menu.Item>
            </Menu>
          );
        }

        return (
          <Dropdown overlay={menu || <div />} trigger={["click"]}>
            <Button type="link" disabled={status !== VerifyStatus.Pending}>
              Действия <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Заявки на активацию {name}</Title>
      <Space style={{ marginBottom: 16 }}>
        <RangePicker
          onChange={(dates) => setDateRange(dates ?? undefined)}
          placeholder={["Начало", "Конец"]}
          format={{ format: "DD.MM.YY" }}
        />
        <Select
          placeholder="Статус"
          style={{ width: 150 }}
          allowClear
          onChange={(value) => setStatus(value)}
        >
          {Object.values(VerifyStatus).map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Space>

      <Table
        columns={columns}
        dataSource={requests}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </div>
  );
}
