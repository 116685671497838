import React, { useState, useEffect } from "react";
import {
  Input,
  Card,
  Row,
  Col,
  Divider,
  Typography,
  Spin,
  List,
  Button,
  Modal,
  Form,
  message,
} from "antd";
import { Pricing } from "../../types/pricing.type";
import api from "../../api/api";

const { Search } = Input;
const { Title, Text } = Typography;

export default function Pricings() {
  const [pricings, setPricings] = useState<Pricing[]>([]);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(12);
  const [totalPricings, setTotalPricings] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editPricingId, setEditPricingId] = useState<string | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchPricings();
  }, [search, page]);

  const fetchPricings = async () => {
    setLoading(true);
    try {
      const params: any = {
        page,
        limit,
      };

      if (search) {
        params.search = search;
      }

      const response = await api.get(`/admin/pricing`, {
        params,
      });
      setPricings(response.data.pricings);
      setTotalPricings(response.data.total);
    } catch (error) {
      console.error("Error fetching pricings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  const handleEdit = (id: string) => {
    const pricingToEdit = pricings.find((pricing) => pricing._id === id);
    if (pricingToEdit) {
      form.setFieldsValue(pricingToEdit);
      setIsEditing(true);
      setEditPricingId(id);
      setIsModalVisible(true);
    }
  };

  const handleDelete = async (id: string) => {
    Modal.confirm({
      title: "Вы уверены, что хотите удалить этот тариф?",
      content: "Это действие не может быть отменено.",
      okText: "Да",
      okType: "danger",
      cancelText: "Нет",
      onOk: async () => {
        try {
          await api.delete(`/admin/pricing/${id}`);
          message.success("Тариф успешно удален.");
          fetchPricings();
        } catch (error) {
          console.error("Error deleting pricing:", error);
          message.error("Ошибка при удалении тарифа.");
        }
      },
    });
  };

  const showAddModal = () => {
    setIsModalVisible(true);
    setIsEditing(false);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (isEditing) {
        await api.put(`/admin/pricing/${editPricingId}`, values);
        message.success("Тариф успешно обновлен.");
      } else {
        await api.post(`/admin/pricing`, values);
        message.success("Тариф успешно добавлен.");
      }
      fetchPricings();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error: any) {
      console.error("Error adding/updating pricing:", error.response.data);
      message.error(error.response.data.error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <div style={{ padding: "24px" }}>
      <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
        Управление Тарифами
      </Title>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "24px", justifyContent: "center" }}
      >
        <Col span={12}>
          <Search
            placeholder="Поиск по городу или стране"
            allowClear
            enterButton
            onSearch={handleSearch}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={showAddModal}>
            Добавить
          </Button>
        </Col>
      </Row>

      {loading ? (
        <Spin
          tip="Загрузка..."
          style={{ display: "block", margin: "0 auto" }}
        />
      ) : (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          }}
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => setPage(page),
            pageSize: limit,
            total: totalPricings,
            current: page,
          }}
          dataSource={pricings}
          renderItem={(pricing) => (
            <List.Item>
              <Card
                style={{
                  marginBottom: "16px",
                  padding: "16px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Title level={4} style={{ marginBottom: "8px" }}>
                  {`${pricing.city}, ${pricing.country}`}
                </Title>
                <Divider />
                <Row>
                  <Col span={12}>
                    <Text strong>Ст. за км:</Text>
                    <Text>{` ${pricing.costPerKm} ₽`}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>Ст. за кг:</Text>
                    <Text>{` ${pricing.costPerKg} ₽`}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Text strong>Ст. за пункт:</Text>
                    <Text>{` ${pricing.costPerPoint} ₽`}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>Часовой пояс:</Text>
                    <Text>{` ${pricing.tz}`}</Text>
                  </Col>
                </Row>
                <Divider dashed />
                <Text strong>Коэффициенты:</Text>
                <Col span={8}>
                  <Text>День: {pricing.coefDay}</Text>
                </Col>
                <Col span={8}>
                  <Text>Вечер: {pricing.coefEvening}</Text>
                </Col>
                <Col span={8}>
                  <Text>Ночь: {pricing.coefNight}</Text>
                </Col>
                <Col span={24}>
                  <Text>
                    Выходные/праздничные: {pricing.coefWeekendHoliday}
                  </Text>
                </Col>
                <Divider />
                <Row justify="center">
                  <Button
                    onClick={() => handleEdit(pricing._id)}
                    style={{ marginTop: "8px", marginRight: "8px" }}
                  >
                    Изменить
                  </Button>
                  <Button
                    danger
                    onClick={() => handleDelete(pricing._id)}
                    style={{ marginTop: "8px" }}
                  >
                    Удалить
                  </Button>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      )}

      <Modal
        title={isEditing ? "Изменить тариф" : "Добавить новый тариф"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="city"
            label="Город"
            rules={[{ required: true, message: "Пожалуйста, введите город!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label="Страна"
            rules={[{ required: true, message: "Пожалуйста, введите страну!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="costPerKg"
            label="Стоимость за кг"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите стоимость за кг!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="costPerKm"
            label="Стоимость за км"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите стоимость за км!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="costPerPoint"
            label="Стоимость за пункт"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите стоимость за пункт!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="tz"
            label="Часовой пояс"
            rules={[
              { required: true, message: "Пожалуйста, введите часовой пояс!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="coefDay"
            label="Коэффициент для дня"
            rules={[
              { required: true, message: "Введите коэффициент для дня!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="coefEvening"
            label="Коэффициент для вечера"
            rules={[
              { required: true, message: "Введите коэффициент для вечера!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="coefNight"
            label="Коэффициент для ночи"
            rules={[
              { required: true, message: "Введите коэффициент для ночи!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="coefWeekendHoliday"
            label="Коэффициент для выходных/праздников"
            rules={[
              {
                required: true,
                message: "Введите коэффициент для выходных/праздников!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
