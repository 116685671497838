import React, { useEffect, useState } from "react";
import { Subcategory } from "../../types/subcategory.type";
import { categories } from "../../constants/categories.constant";
import { getCategoryColor } from "../../utils/tag";
import {
  Select,
  Typography,
  Table,
  Space,
  Tag,
  Input,
  Menu,
  Dropdown,
  Button,
  Modal,
  Form,
  message,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import api from "../../api/api";

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

export default function Subcategories() {
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<Subcategory | null>(null);
  const [form] = Form.useForm();

  const fetchSubcategories = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/subcategory/`, {
        params: {
          page,
          categoryId,
          limit: pageSize,
          search: searchTerm,
        },
      });
      setSubcategories(response.data.subcategories);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSubcategories();
  }, [page, pageSize, categoryId, searchTerm]);

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Категория",
      dataIndex: "categoryId",
      key: "categoryId",
      render: (categoryId: number) => {
        const categoryObj = categoryId
          ? categories.find(
              (cat) => cat.id.toString() === categoryId.toString()
            )
          : null;

        if (categoryObj) {
          const tagColor = getCategoryColor(categoryObj.id);
          return <Tag color={tagColor}>{categoryObj.name}</Tag>;
        }
      },
    },
    {
      title: "",
      key: "actions",
      render: (_: any, subcategory: Subcategory) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={() => handleEdit(subcategory)}>
              Изменить
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <Button type="link">
              Действия <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setPage(1);
  };

  const handleFormSubmit = async (values: Partial<Subcategory>) => {
    try {
      if (selectedSubcategory) {
        await api.put(`/subcategory/${selectedSubcategory._id}`, values);
        message.success("Подкатегория успешно обновлена.");
      } else {
        await api.post(`/subcategory`, values);
        message.success("Подкатегория успешно добавлена.");
      }
      fetchSubcategories();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error: any) {
      console.error("Error adding/updating subcategory:", error.response.data);
      message.error(error.response.data.error);
    }
  };

  const showAddModal = () => {
    setSelectedSubcategory(null);
    setIsModalVisible(true);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedSubcategory(null);
    form.resetFields();
  };
  const handleEdit = (subcategory: Subcategory) => {
    if (subcategory) {
      form.setFieldsValue(subcategory);
      setSelectedSubcategory(subcategory);
      setIsModalVisible(true);
    }
  };
  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Подкатегории</Title>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Поиск"
          onSearch={handleSearch}
          style={{ width: 200 }}
          allowClear
        />

        <Select
          placeholder="Категория"
          style={{ width: 200 }}
          allowClear
          onChange={(value) => setCategoryId(value)}
        >
          {categories.map((cat) => (
            <Option key={cat.id} value={cat.id}>
              {cat.name}
            </Option>
          ))}
        </Select>
        <Button type="primary" onClick={showAddModal}>
          Добавить
        </Button>
      </Space>
      <Table
        columns={columns}
        dataSource={subcategories}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />

      <Modal
        title={
          selectedSubcategory ? "Изменить подкатегорию" : "Создать подкатегорию"
        }
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
          <Form.Item
            name="name"
            label="Название"
            rules={[
              { required: true, message: "Пожалуйста, введите название!" },
            ]}
          >
            <Input />
          </Form.Item>
          {!selectedSubcategory && (
            <Form.Item
              name="categoryId"
              label="Категория"
              rules={[
                { required: true, message: "Пожалуйста, выберите категорию!" },
              ]}
            >
              <Select placeholder="Категория" style={{ width: 200 }}>
                {categories.map((cat) => (
                  <Option key={cat.id} value={cat.id}>
                    {cat.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
}
