
export const categories = [
  { id: 1, name: "Рестораны" },
  { id: 2, name: "Продукты" },
  { id: 3, name: "Аптека" },
  { id: 4, name: "Цветы" },
  { id: 5, name: "Зоо товары" },
  { id: 6, name: "Стройматериалы" },
  { id: 7, name: "Рынки" },
  { id: 8, name: "Фермер. товары" },
];
