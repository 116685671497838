import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Tooltip,
  message,
  Image,
  Table,
  Menu,
  Dropdown,
} from "antd";
import {
  Seller,
  SellerStatus,
  ISchedule,
  IFreeDelivery,
} from "../../types/seller.type";
import { categories } from "../../constants/categories.constant";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Point } from "../../types/order.type";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { API } from "../../config/config";

const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

export default function Sellers() {
  const navigate = useNavigate();
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [searchName, setSearchName] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [category, setCategory] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState<SellerStatus | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalSellers, setTotalSellers] = useState<number>(0);

  const fetchSellers = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/admin/sellers`, {
        params: {
          name: searchName,
          city: searchCity,
          category,
          status,
          page: currentPage,
          limit: pageSize,
        },
      });
      setSellers(response.data.sellers);
      setTotalSellers(response.data.total);
    } catch (error) {
      console.error("Error fetching sellers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSellers();
  }, [searchName, searchCity, category, status, currentPage, pageSize]);

  const toggleSellerStatus = async (
    sellerId: string,
    currentStatus: SellerStatus
  ) => {
    try {
      const newStatus =
        currentStatus === SellerStatus.ACTIVE
          ? SellerStatus.INACTIVE
          : SellerStatus.ACTIVE;

      await api.post(`/admin/seller/${sellerId}/status`, {
        status: newStatus,
      });

      message.success(
        `Статус продавца обновлен на ${
          newStatus === SellerStatus.ACTIVE ? "Активен" : "Неактивен"
        }`
      );
      fetchSellers();
    } catch (error) {
      console.error("Error updating seller status:", error);
      message.error("Не удалось обновить статус продавца");
    }
  };

  const handleSearchName = (value: string) => {
    setSearchName(value);
    fetchSellers();
  };

  const handleSearchCity = (value: string) => {
    setSearchCity(value);
    fetchSellers();
  };

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Баланс",
      dataIndex: "balance",
      key: "balance",
      render: (balance: { amount: number }) =>
        new Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(balance.amount),
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Оценка",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Кол-во отзывов",
      dataIndex: "reviewsCount",
      key: "reviewsCount",
    },
    {
      title: "График",
      dataIndex: "schedule",
      key: "schedule",
      render: (schedule: ISchedule) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "14px",
            color: "#333",
          }}
        >
          {schedule.map((day, index) => {
            let scheduleText = "";
            const dayName = day.dayOfWeek;

            if (day.isDayOff) {
              scheduleText = `${dayName}: Выходной`;
            } else if (day.is24Hours) {
              scheduleText = `${dayName}: 24 ч`;
            } else {
              scheduleText = `${dayName}: ${day.openTime} - ${day.closeTime}`;
            }

            return (
              <span
                key={index}
                style={{
                  margin: "0px 4px",
                  backgroundColor: "#f5f5f5",
                  padding: "2px 6px",
                  borderRadius: "4px",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                }}
              >
                {scheduleText}
              </span>
            );
          })}
        </div>
      ),
    },
    {
      title: "Категория",
      dataIndex: "category",
      key: "category",
      render: (category: number) => {
        return category
          ? categories.find((cat) => cat.id.toString() === category.toString())
              ?.name || "Не указана"
          : "Не указана";
      },
    },
    {
      title: "Подкатегория",
      dataIndex: "subcategoryName",
      key: "subcategoryName",
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      render: (address: Point) => {
        if (!address) {
          return "Нет";
        }
        return `${address.city}, ${address.street}, дом ${address.house}, вход ${address.entrance}, кв ${address.apartment}, ${address.floor} этаж`;
      },
    },
    {
      title: "Бесплатная доставка",
      dataIndex: "freeDelivery",
      key: "freeDelivery",
      render: (freeDelivery: IFreeDelivery | undefined) => {
        if (!freeDelivery) {
          return "Нет";
        }
        return `Стоимость от ${freeDelivery.minCost} ₽, радиус ${freeDelivery.radius} км, вес до ${freeDelivery.weight} кг`;
      },
    },
    {
      title: "Баннер",
      dataIndex: "banner",
      key: "banner",
      render: (banner: string) => (
        <Image
          width={100}
          src={`${API}/${banner}`}
          preview={{ src: `${API}/${banner}` }}
          alt="Фото"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: "Фото",
      dataIndex: "photoUri",
      key: "photoUri",
      render: (photoUri: string) => (
        <Image
          width={50}
          src={`${API}/${photoUri}`}
          preview={{ src: `${API}/${photoUri}` }}
          alt="Фото"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: "",
      key: "actions",
      render: (_: any, seller: Seller) => {
        const menu = (
          <Menu>
            <Menu.Item
              onClick={() =>
                navigate(`/${seller._id}/seller/${seller.name}/orders`)
              }
            >
              Заказы
            </Menu.Item>
            <Menu.Item
              onClick={() => navigate(`/${seller._id}/${seller.name}/products`)}
            >
              Товары
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(`/reviews/seller/${seller._id}/${seller.name}`)
              }
            >
              Отзывы
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(`/${seller._id}/${seller.name}/vacancies`)
              }
            >
              Вакансии
            </Menu.Item>
            <Menu.Item
              onClick={() => navigate(`/${seller._id}/${seller.name}/workers`)}
            >
              Сотрудники
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(`/${seller._id}/${seller.name}/dishonest-workers`)
              }
            >
              Недобр.сотрудники
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(`/${seller._id}/${seller.name}/transactions`)
              }
            >
              Транзакции
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                navigate(`/requests/withdrawals/${seller._id}/${seller.name}`)
              }
            >
              Заявки на вывод
            </Menu.Item>
            <Menu.Item
              onClick={() => toggleSellerStatus(seller._id, seller.status)}
            >
              {seller.status === SellerStatus.ACTIVE
                ? "Деактивировать"
                : "Активировать"}
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <Button type="link">
              Действия <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  return (
    <div style={{ padding: "24px" }}>
      <Title level={3} style={{ textAlign: "center", marginBottom: "24px" }}>
        Управление Продавцами
      </Title>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={6}>
          <Search
            placeholder="Поиск по имени"
            onSearch={handleSearchName}
            allowClear
            onClear={() => handleSearchName("")}
          />
        </Col>
        <Col span={6}>
          <Tooltip title="Введите город">
            <Search
              placeholder="Поиск по городу"
              onSearch={handleSearchCity}
              allowClear
              onClear={() => handleSearchCity("")}
            />
          </Tooltip>
        </Col>
        <Col span={6}>
          <Select
            placeholder="Категория"
            value={category}
            onChange={(value) => setCategory(value || undefined)}
            style={{ width: "100%" }}
            allowClear
          >
            {categories.map((cat) => (
              <Option key={cat.id} value={cat.id}>
                {cat.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            placeholder="Статус"
            value={status}
            onChange={setStatus}
            style={{ width: "100%" }}
            allowClear
          >
            <Option value={SellerStatus.ACTIVE}>
              <CheckCircleOutlined /> Активен
            </Option>
            <Option value={SellerStatus.INACTIVE}>
              <CloseCircleOutlined /> Неактивен
            </Option>
          </Select>
        </Col>
      </Row>
      <Divider />

      <Table
        columns={columns}
        dataSource={sellers}
        rowKey={(record) => record._id}
        loading={loading}
        pagination={{
          current: currentPage,
          total: totalSellers,
          pageSize: pageSize,
          onChange: (page, size) => {
            setCurrentPage(page);
            if (size !== pageSize) setPageSize(size);
          },
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}
