import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DishonestWorker } from "../../types/dishonestWorker.type";
import { ColumnsType } from "antd/es/table";
import { Input, Typography, Table, Space } from "antd";
import api from "../../api/api";

const { Title } = Typography;
const { Search } = Input;
export default function DishonestWorkers() {
  const { sellerId, name } = useParams();
  const [workers, setWorkers] = useState<DishonestWorker[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);

  const fetchWorkers = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/worker/seller/${sellerId}/dishonests`, {
        params: {
          page,
          limit: pageSize,
          search: searchTerm,
        },
      });
      setWorkers(response.data.workers);
      setTotal(response.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkers();
  }, [page, pageSize, searchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setPage(1);
  };

  const columns: ColumnsType<DishonestWorker> = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Фамилия",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Причина",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
  ];
  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Недобросовестные сотрудники {name}</Title>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Поиск"
          onSearch={handleSearch}
          style={{ width: 300 }}
          allowClear
        />
      </Space>
      <Table
        columns={columns}
        dataSource={workers}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </div>
  );
}
