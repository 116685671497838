import React from "react";
import "@fontsource/fira-mono";
import "@fontsource/fira-mono/400.css";
import "@fontsource/fira-mono/500.css";
import Login from "./pages/Login/login.page";
import { Route, Routes } from "react-router-dom";
import Root from "./components/Root/root.componen";
import Dashboard from "./pages/Dashboard/dashboard.page";
import PrivateRoutes from "./utils/PrivateRoutes";
import Sellers from "./pages/Sellers/sellers.page";
import Clients from "./pages/Clients/clients.page";
import Couriers from "./pages/Couriers/couriers.page";
import Pricings from "./pages/Pricings/pricings.page";
import Orders from "./pages/Orders/orders.page";
import Products from "./pages/Products/products.page";
import Vacancies from "./pages/Vacancies/vacancies.page";
import Workers from "./pages/Workers/workers.page";
import DishonestWorkers from "./pages/DishonestWorkers/dishonestWorkers.page";
import Transactions from "./pages/Transactions/transactions.page";
import WithdrawalRequests from "./pages/WithdrawalRequests/withdrawalRequests.page";
import VerificationRequests from "./pages/VerificationRequests/verificationRequests.page";
import Subcategories from "./pages/Subcategories/subcategories.page";
import Reviews from "./pages/Reviews/reviews.page";

function App() {
  const token = localStorage.getItem("token");

  return (
    <div className="App">
      <Routes>
        {!token && <Route path="login" element={<Login />} />}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Root />}>
            <Route index element={<Dashboard />} />
            <Route path="sellers" element={<Sellers />} />
            <Route path="clients" element={<Clients />} />
            <Route path="couriers" element={<Couriers />} />
            <Route path="pricings" element={<Pricings />} />
            <Route path=":itemId/:type/:name/orders" element={<Orders />} />
            <Route path=":sellerId/:name/products" element={<Products />} />
            <Route path=":sellerId/:name/vacancies" element={<Vacancies />} />
            <Route path=":sellerId/:name/workers" element={<Workers />} />
            <Route
              path=":sellerId/:name/dishonest-workers"
              element={<DishonestWorkers />}
            />
            <Route
              path=":itemId/:name/transactions"
              element={<Transactions />}
            />
            <Route
              path="requests/withdrawals/:owner/:name"
              element={<WithdrawalRequests />}
            />
            <Route
              path="requests/withdrawals"
              element={<WithdrawalRequests />}
            />
            <Route
              path="requests/verification/:courierId/:name"
              element={<VerificationRequests />}
            />
            <Route
              path="requests/verification"
              element={<VerificationRequests />}
            />
            <Route path="subcategories" element={<Subcategories />} />
            <Route path="reviews/:type/:id/:name" element={<Reviews />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
