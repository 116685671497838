import {
  DatePicker,
  Select,
  Space,
  Typography,
  Table,
  Menu,
  Dropdown,
  Button,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  UserType,
  WithdrawalReq,
  WithdrawalStatus,
} from "../../types/withdrawalReq.type";
import { DownOutlined } from "@ant-design/icons";
import api from "../../api/api";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function WithdrawalRequests() {
  const { owner, name } = useParams();
  const [withdrawals, setWithdrawals] = useState<WithdrawalReq[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);
  const [userType, setUserType] = useState<UserType | undefined>(undefined);
  const [status, setStatus] = useState<WithdrawalStatus | undefined>(undefined);

  const fetchWithdrawals = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/transaction/withdrawals`, {
        params: {
          page,
          userType,
          owner,
          status,
          limit: pageSize,
          startDate: dateRange ? dateRange[0]?.format("DD.MM.YY") : undefined,
          endDate: dateRange ? dateRange[1]?.format("DD.MM.YY") : undefined,
        },
      });
      setWithdrawals(response.data.withdrawals);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchWithdrawals();
  }, [page, pageSize, dateRange, userType, owner, status]);

  const handleAccept = async (id: string) => {
    try {
      await api.post(`/transaction/withdrawal/${id}/accept`);
      message.success("Заявка успешно принята");
      fetchWithdrawals();
    } catch (error: any) {
      message.error(
        error.response?.data?.error || "Ошибка при принятии заявки"
      );
    }
  };

  const handleReject = async (id: string) => {
    try {
      await api.post(`/transaction/withdrawal/${id}/reject`);
      message.success("Заявка успешно отклонена");
      fetchWithdrawals();
    } catch (error: any) {
      message.error(
        error.response?.data?.error || "Ошибка при отклонении заявки"
      );
    }
  };

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "От кого",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => {
        return new Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(amount);
      },
    },
    {
      title: "Карта",
      dataIndex: "cardNumber",
      key: "cardNumber",
      render: (cardNumber: number) => {
        const formattedCardNumber = cardNumber
          .toString()
          .replace(/\D/g, "")
          .replace(/(\d{4})(?=\d)/g, "$1 ");

        return formattedCardNumber;
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: WithdrawalStatus) => {
        let statusColor;
        switch (status) {
          case WithdrawalStatus.Completed:
            statusColor = "green";
            break;
          case WithdrawalStatus.Rejected:
            statusColor = "red";
            break;
          case WithdrawalStatus.Pending:
            statusColor = "orange";
            break;
          default:
            statusColor = "gray";
        }
        return <span style={{ color: statusColor }}>{status}</span>;
      },
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      key: "actions",
      render: (_: any, withdrawal: WithdrawalReq) => {
        const { status, _id } = withdrawal;

        let menu = null;
        if (status === WithdrawalStatus.Pending) {
          menu = (
            <Menu>
              <Menu.Item onClick={() => handleAccept(_id)}>Принять</Menu.Item>
              <Menu.Item danger onClick={() => handleReject(_id)}>
                Отклонить
              </Menu.Item>
            </Menu>
          );
        } else if (status === WithdrawalStatus.Completed) {
          menu = (
            <Menu>
              <Menu.Item disabled>Завершено</Menu.Item>
            </Menu>
          );
        } else if (status === WithdrawalStatus.Rejected) {
          menu = (
            <Menu>
              <Menu.Item disabled>Отклонено</Menu.Item>
            </Menu>
          );
        }

        return (
          <Dropdown overlay={menu || <div />} trigger={["click"]}>
            <Button type="link" disabled={status !== WithdrawalStatus.Pending}>
              Действия <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Заявки на вывод {name}</Title>
      <Space style={{ marginBottom: 16 }}>
        <RangePicker
          onChange={(dates) => setDateRange(dates ?? undefined)}
          placeholder={["Начало", "Конец"]}
          format={{ format: "DD.MM.YY" }}
        />
        {!owner && (
          <Select
            placeholder="От кого"
            style={{ width: 150 }}
            allowClear
            onChange={(value) => setUserType(value)}
          >
            {Object.values(UserType).map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        )}
        <Select
          placeholder="Статус"
          style={{ width: 150 }}
          allowClear
          onChange={(value) => setStatus(value)}
        >
          {Object.values(WithdrawalStatus).map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Space>

      <Table
        columns={columns}
        dataSource={withdrawals}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </div>
  );
}
