import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Transaction, TransactionType } from "../../types/transaction.type";
import { DatePicker, Select, Typography, Table, Space } from "antd";
import api from "../../api/api";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Transactions() {
  const { itemId, name } = useParams();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);
  const [transactionType, setTransactionType] = useState<
    TransactionType | undefined
  >(undefined);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/admin/transactions/${itemId}`, {
        params: {
          page,
          limit: pageSize,
          type: transactionType,
          startDate: dateRange ? dateRange[0]?.format("DD.MM.YY") : undefined,
          endDate: dateRange ? dateRange[1]?.format("DD.MM.YY") : undefined,
        },
      });
      setTransactions(response.data.transactions);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTransactions();
  }, [page, pageSize, dateRange, transactionType]);

  const columns = [
    {
      title: "Сумма",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
  ];
  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Транзакции {name}</Title>
      <Space style={{ marginBottom: 16 }}>
        <RangePicker
          onChange={(dates) => setDateRange(dates ?? undefined)}
          placeholder={["Начало", "Конец"]}
          format={{ format: "DD.MM.YY" }}
        />
        <Select
          placeholder="Тип"
          style={{ width: 150 }}
          allowClear
          onChange={(value) => setTransactionType(value)}
        >
          {Object.values(TransactionType).map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </Space>

      <Table
        columns={columns}
        dataSource={transactions}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </div>
  );
}
