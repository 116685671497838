export enum TransactionType {
  CREDIT = "Зачисление",
  DEBIT = "Списание",
}

export interface Transaction {
  _id: string;
  amount: number;
  type: TransactionType;
  description?: string;
  date: string;
}
