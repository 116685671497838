import { Tag, Typography, Table, Image, Select, Space, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IOption, Product } from "../../types/product.type";
import { ColumnsType } from "antd/es/table";
import { categories } from "../../constants/categories.constant";
import { getCategoryColor } from "../../utils/tag";
import api from "../../api/api";
import { API } from "../../config/config";

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

export default function Products() {
  const { sellerId, name } = useParams();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [category, setCategory] = useState<number | undefined>(undefined);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/product/seller/${sellerId}/admin`, {
        params: {
          page,
          limit: pageSize,
          category,
          search: searchTerm,
        },
      });

      setProducts(response.data.products);
      setTotal(response.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [page, pageSize, category, searchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setPage(1);
  };

  const columns: ColumnsType<Product> = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Категория",
      dataIndex: "category",
      key: "category",
      render: (category: number) => {
        const categoryObj = category
          ? categories.find((cat) => cat.id.toString() === category.toString())
          : null;

        if (categoryObj) {
          const tagColor = getCategoryColor(categoryObj.id);
          return <Tag color={tagColor}>{categoryObj.name}</Tag>;
        }
      },
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      width: 400,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
        >
          {text || "Без описания"}
        </div>
      ),
    },
    {
      title: "Вес (кг)",
      dataIndex: "weight",
      key: "weight",
      render: (weight: number) => <span>{`${weight / 1000} кг`}</span>,
    },
    {
      title: "Фото товара",
      dataIndex: "photos",
      key: "photos",
      render: (photos: string[]) => (
        <div>
          {photos && photos.length > 0 ? (
            photos.map((photo, index) => (
              <Image
                key={index}
                src={`${API}/${photo}`}
                width={50}
                preview={{ src: `${API}/${photo}` }}
                alt="Фото"
                loading="lazy"
                style={{
                  cursor: "pointer",
                  borderRadius: 4,
                  border: "1px solid #ddd",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                }}
              />
            ))
          ) : (
            <span>Нет фото</span>
          )}
        </div>
      ),
    },
    {
      title: "Раздел",
      dataIndex: "chapterName",
      key: "chapterName",
      render: (chapterName: string) => <span>{chapterName}</span>,
    },
    {
      title: "Опции",
      dataIndex: "options",
      key: "options",
      render: (options: IOption[]) => (
        <div>
          {options.map((option) => (
            <div key={option.choice} style={{ marginBottom: 5 }}>
              <Tag color="green">
                {option.title}: {option.choice} - {option.price}₽
              </Tag>
            </div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Товары {name}</Title>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Поиск"
          onSearch={handleSearch}
          style={{ width: 200 }}
          allowClear
        />

        <Select
          placeholder="Категория"
          style={{ width: 150 }}
          allowClear
          onChange={(value) => setCategory(value)}
        >
          {categories.map((cat) => (
            <Option key={cat.id} value={cat.id}>
              {cat.name}
            </Option>
          ))}
        </Select>
      </Space>
      <Table
        columns={columns}
        dataSource={products}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </div>
  );
}
