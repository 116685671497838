export enum VerifyStatus {
  Pending = "Ожидается",
  Rejected = "Отклонено",
  Completed = "Завершено",
}

export interface Request {
  _id: string;
  courierId: string;
  name: string;
  surname: string;
  docUri: string;
  status: VerifyStatus;
  date: string;
}
