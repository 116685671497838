import {
  Button,
  DatePicker,
  Input,
  Select,
  Space,
  Tag,
  Typography,
  Table,
  Drawer,
  Tooltip,
  Divider,
  Card,
  List,
  Row,
  Col,
  Statistic,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Order, OrderProduct, OrderStatus } from "../../types/order.type";
import { getTagColor } from "../../utils/tag";
import { ColumnsType } from "antd/es/table";
import api from "../../api/api";

const { Title, Paragraph } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

export default function Orders() {
  const { itemId, type, name } = useParams();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);
  const [status, setStatus] = useState<OrderStatus | undefined>(undefined);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/order/${type}/${itemId}`, {
        params: {
          page,
          limit: pageSize,
          status: status,
          trackingId: searchTerm,
          startDate: dateRange ? dateRange[0]?.format("DD.MM.YY") : undefined,
          endDate: dateRange ? dateRange[1]?.format("DD.MM.YY") : undefined,
        },
      });
      setOrders(response.data.orders);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOrders();
  }, [page, pageSize, dateRange, status, searchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setPage(1);
  };
  const getTotalPrice = (products: OrderProduct[]) =>
    products.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    );

  const getTotalWeight = (products: OrderProduct[]) =>
    products.reduce(
      (sum, product) => sum + product.weight * product.quantity,
      0
    );

  const showDrawer = (order: Order) => {
    setCurrentOrder(order);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setCurrentOrder(null);
  };

  const columns: ColumnsType<Order> = [
    {
      title: "ID отслеживания",
      dataIndex: "trackingId",
      key: "trackingId",
      ellipsis: true,
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Адрес клиента",
      key: "clientPoint",
      render: (text: any, record: Order) => (
        <div
          style={{
            maxWidth: 250,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {record.clientPoint.city} , {record.clientPoint.street} , дом{" "}
          {record.clientPoint.house} , вход {record.clientPoint.entrance} кв{" "}
          {record.clientPoint.apartment} , {record.clientPoint.floor} этаж
        </div>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: OrderStatus) => (
        <Tag color={getTagColor(status)}>{status}</Tag>
      ),
    },
    {
      title: "Цена товаров",
      key: "totalPrice",
      render: (text: any, record: Order) => <span>{record.price}₽</span>,
    },
    {
      title: "Цена доставки (клиента)",
      key: "deliveryAmount",
      render: (text: any, record: Order) => (
        <span>{record.deliveryAmount}₽</span>
      ),
    },
    {
      title: "Цена доставки (в общем)",
      key: "totalDeliveryAmount",
      render: (text: any, record: Order) => (
        <span>{record.totalDeliveryAmount}₽</span>
      ),
    },
    {
      title: "Сумма",
      key: "total",
      render: (text: any, record: Order) => <span>{record.total}₽</span>,
    },
    {
      title: "Общий вес",
      key: "totalWeight",
      render: (text: any, record: Order) => (
        <span>
          {record.weight / 1000}
          кг
        </span>
      ),
    },
    {
      title: "Действия",
      key: "actions",
      render: (text: any, record: Order) => (
        <Button type="primary" onClick={() => showDrawer(record)}>
          Подробнее
        </Button>
      ),
      fixed: "right",
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Заказы {name}</Title>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Поиск по ID отслеживания"
          onSearch={handleSearch}
          style={{ width: 200 }}
          allowClear
        />
        <RangePicker
          onChange={(dates) => setDateRange(dates ?? undefined)}
          placeholder={["Начало", "Конец"]}
          format={{ format: "DD.MM.YY" }}
        />
        <Select
          placeholder="Статус"
          style={{ width: 150 }}
          allowClear
          onChange={(value) => setStatus(value)}
        >
          {Object.values(OrderStatus).map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Space>

      <Table
        columns={columns}
        dataSource={orders}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
      <Drawer
        width={720}
        onClose={onClose}
        open={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {currentOrder && (
          <>
            <Title level={4} style={{ color: "#1890ff" }}>
              Детали заказа
            </Title>
            <Paragraph>
              <strong>ID отслеживания:</strong>{" "}
              <Tooltip title="Уникальный идентификатор заказа">
                {currentOrder.trackingId}
              </Tooltip>
            </Paragraph>
            <Paragraph>
              <strong>Дата доставки:</strong> {currentOrder.date}
            </Paragraph>
            <Paragraph>
              <strong>Имя клиента:</strong> {currentOrder.clientName}
            </Paragraph>
            <Paragraph>
              <strong>Адрес клиента:</strong> {currentOrder.clientPoint.city} ,{" "}
              {currentOrder.clientPoint.street} , дом{" "}
              {currentOrder.clientPoint.house} , вход{" "}
              {currentOrder.clientPoint.entrance} кв{" "}
              {currentOrder.clientPoint.apartment} ,{" "}
              {currentOrder.clientPoint.floor} этаж
            </Paragraph>
            <Paragraph>
              <strong>Телефон клиента:</strong> {currentOrder.clientPhone}
            </Paragraph>
            <Paragraph>
              <strong>ID курьера:</strong>{" "}
              {currentOrder.courierId || <Tag color="red">Нет данных</Tag>}
            </Paragraph>
            <Paragraph>
              <strong>Телефон курьера:</strong>{" "}
              {currentOrder.courierPhone || <Tag color="red">Нет данных</Tag>}
            </Paragraph>
            <Divider />

            <Title level={4} style={{ color: "#1890ff" }}>
              Продавцы
            </Title>
            {currentOrder.sellerProducts.map((sellerProduct, index) => (
              <Card key={index} style={{ marginBottom: 16 }}>
                <Paragraph>
                  <strong>Продавец:</strong> {sellerProduct.name}
                </Paragraph>
                <Paragraph>
                  <strong>Адрес продавца:</strong>{" "}
                  {currentOrder.points[index]?.city},{" "}
                  {currentOrder.points[index]?.street}, дом{" "}
                  {currentOrder.points[index]?.house}, вход{" "}
                  {currentOrder.points[index]?.entrance}, кв.{" "}
                  {currentOrder.points[index]?.apartment}, этаж{" "}
                  {currentOrder.points[index]?.floor}
                </Paragraph>
                <Paragraph>
                  <strong>Сумма товаров:</strong>{" "}
                  {getTotalPrice(sellerProduct.products).toFixed(2)} ₽
                </Paragraph>
                <Paragraph>
                  <strong>Вес:</strong>{" "}
                  {(getTotalWeight(sellerProduct.products) / 1000).toFixed(2)}{" "}
                  кг
                </Paragraph>
                <Paragraph>
                  <strong>Заплатил за доставку:</strong>{" "}
                  {sellerProduct.deliveryAmount} ₽
                </Paragraph>
                <List
                  itemLayout="horizontal"
                  dataSource={sellerProduct.products}
                  renderItem={(product) => (
                    <List.Item>
                      <List.Item.Meta
                        title={product.title}
                        description={`Опция: ${product.selectedOption} | Цена: ${product.price} ₽ | Количество: ${product.quantity}`}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            ))}
            <Divider />
            <Divider />
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="Общая цена"
                  value={currentOrder.total.toFixed(2)}
                  suffix="₽"
                />
                <Statistic
                  title="Цена товаров"
                  value={currentOrder.price.toFixed(2)}
                  suffix="₽"
                />
                <Statistic
                  title="Цена за доставку (клиента)"
                  value={currentOrder.deliveryAmount.toFixed(2)}
                  suffix="₽"
                />
                <Statistic
                  title="Цена за доставку (в общем)"
                  value={currentOrder.totalDeliveryAmount.toFixed(2)}
                  suffix="₽"
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Общий вес"
                  value={(currentOrder.weight / 1000).toFixed(2)}
                  suffix="кг"
                />
              </Col>
            </Row>
          </>
        )}
      </Drawer>
    </div>
  );
}
