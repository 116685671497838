export enum Schedule {
  FullTime = "полный день",
  PartTime = "неполный день",
  Flexible = "гибкий график",
}

export enum SalaryPer {
  Hour = "час",
  Month = "месяц",
  Day = "день",
}
export interface Vacancy {
  _id: string;
  title: string;
  schedule: Schedule;
  salary: number;
  salaryPer: SalaryPer;
  requirements: string;
  responsibilities: string;
  owner: string;
  date: string;
}
