import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SalaryPer, Schedule, Vacancy } from "../../types/vacancy.type";
import { ColumnsType } from "antd/es/table";
import { Input, Select, Space, Typography, Table } from "antd";
import api from "../../api/api";

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

export default function Vacancies() {
  const { sellerId, name } = useParams();
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [salaryPer, setSalaryPer] = useState<SalaryPer | undefined>(undefined);
  const [schedule, setSchedule] = useState<Schedule | undefined>(undefined);
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const fetchVacancies = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/vacancy/user/${sellerId}`, {
        params: {
          page,
          limit: pageSize,
          search: searchTerm,
          salaryPer,
          schedule,
          sortBy,
          order: sortOrder,
        },
      });
      setVacancies(response.data.vacancies);
      setTotal(response.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVacancies();
  }, [page, pageSize, searchTerm, salaryPer, schedule, sortBy, sortOrder]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setPage(1);
  };

  const columns: ColumnsType<Vacancy> = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: "График",
      dataIndex: "schedule",
      key: "schedule",
    },
    {
      title: "Запрплата в",
      dataIndex: "salaryPer",
      key: "salaryPer",
    },
    {
      title: "Запрплата",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "Требования",
      dataIndex: "requirements",
      key: "requirements",
      width: 400,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Обязаности",
      dataIndex: "responsibilities",
      key: "responsibilities",
      width: 400,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
  ];
  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Вакансии {name}</Title>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Поиск"
          onSearch={handleSearch}
          style={{ width: 200 }}
          allowClear
        />
        <Select
          placeholder="График"
          style={{ width: 150 }}
          allowClear
          onChange={(value) => setSchedule(value)}
        >
          {Object.values(Schedule).map((schedule) => (
            <Option key={schedule} value={schedule}>
              {schedule}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Зарплата в"
          style={{ width: 150 }}
          allowClear
          onChange={(value) => setSalaryPer(value)}
        >
          {Object.values(SalaryPer).map((salaryPer) => (
            <Option key={salaryPer} value={salaryPer}>
              {salaryPer}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Сортировать по"
          defaultValue="date"
          style={{ width: 150 }}
          onChange={(value) => setSortBy(value)}
        >
          <Option value="date">Дата</Option>
          <Option value="salary">Зарплата</Option>
        </Select>
        <Select
          defaultValue="desc"
          style={{ width: 150 }}
          onChange={(value: "asc" | "desc") => setSortOrder(value)}
        >
          <Option value="asc">По возрастанию</Option>
          <Option value="desc">По убыванию</Option>
        </Select>
      </Space>
      <Table
        columns={columns}
        dataSource={vacancies}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </div>
  );
}
