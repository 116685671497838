import axios from "axios";
import { API } from "../config/config";

const api = axios.create({
  baseURL: API,
  withCredentials: false,
});

const getToken = () => localStorage.getItem("token");
const removeToken = () => localStorage.removeItem("token");
const removeAuthState = () => localStorage.removeItem("authState");

api.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._isRetry
    ) {
      removeToken();
      removeAuthState();
      originalRequest._isRetry = true;

      window.location.href = "/login";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default api;
